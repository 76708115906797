import { Component, Input, OnInit } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';
import { Observable, Subscription } from 'rxjs';
import { MqttMessage } from 'src/app/models/MqttMessage';
import { Telecontrol } from 'src/app/models/Telecontrol';
import { SocketMqttService } from 'src/app/services/socket-mqtt.service';
import { TelecontrolMessages } from 'src/app/shared/Telecontrol-Messages';

@Component({
  selector: 'app-telecontrols-messages-grid',
  templateUrl: './telecontrols-messages-grid.component.html',
  styleUrls: ['./telecontrols-messages-grid.component.css'],
})
export class TelecontrolsMessagesGridComponent implements OnInit {
  @Input() telecontrol = {} as Telecontrol;

  submitBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

  messages$ = new Observable<MqttMessage>();

  constructor(private socketMqttService: SocketMqttService) {}

  ngOnInit() {
    this.messages$ = this.socketMqttService.getMessageObservable(
      this.telecontrol.MQTT.Identifier,
    );
  }

  onUpdate() {
    this.submitBtnState = ClrLoadingState.LOADING;

    // this.mqttService
    //   .updateMessages(this.client)
    //   .then((result) => {
    //     this.submitBtnState = ClrLoadingState.SUCCESS;
    //   })
    //   .catch((error) => {
    //     this.submitBtnState = ClrLoadingState.ERROR;
    //   });
  }
}
