<ng-container *ngIf="messages$ | async as messages">
  <clr-datagrid>
    <clr-dg-placeholder>Wir konnten keine Einträge finden!</clr-dg-placeholder>
    <clr-dg-column [clrDgField]="'Id'">
      <ng-container *clrDgHideableColumn="{ hidden: true }">
        <cds-icon shape="hashtag"></cds-icon>&nbsp;Id
      </ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'Class'">
      <ng-container *clrDgHideableColumn="{ hidden: false }">
        Art
      </ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'Timestamp'">
      <ng-container *clrDgHideableColumn="{ hidden: false }">
        Zeit
      </ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'Message'">
      <ng-container *clrDgHideableColumn="{ hidden: false }">
        Meldung
      </ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'System'">
      <ng-container *clrDgHideableColumn="{ hidden: false }">
        System
      </ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'Location'">
      <ng-container *clrDgHideableColumn="{ hidden: false }">
        Ort
      </ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'Device'">
      <ng-container *clrDgHideableColumn="{ hidden: false }">
        Gerät
      </ng-container>
    </clr-dg-column>
    <clr-dg-row
      *clrDgItems="let message of messages.Signals"
      [clrDgItem]="message"
    >
      <clr-dg-cell>{{ message.Id }}</clr-dg-cell>
      <clr-dg-cell>{{ message.Class }}</clr-dg-cell>
      <clr-dg-cell>{{
        message.Timestamp | date: "dd.MM.YYYY HH:mm:ss"
      }}</clr-dg-cell>
      <clr-dg-cell>{{ message.Message }}</clr-dg-cell>
      <clr-dg-cell>{{ message.System }}</clr-dg-cell>
      <clr-dg-cell>{{ message.Location }}</clr-dg-cell>
      <clr-dg-cell>{{ message.Device }}</clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="10">
        <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"
          >Meldungen pro Seite</clr-dg-page-size
        >
        {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} von
        {{ pagination.totalItems }} Meldungen
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
  <p></p>
</ng-container>
